import React, { useLayoutEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import Modal from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { FileFormatType } from '+types';
import { AccessDataT, IdentityTabsT } from '+types/identity';
import { APIDownload, filteredOutObjectProperty, getDate, history, isAllowed, logError, queriesParams } from '+utils';

import BillingTable from '../../Components/BillingTable';
import HeroSection from '../../Components/HeroSection';
import VerificationEventTable  from '../../Components/VerificationEventTable';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function MerchantDetails() {
  const userAccess = useSetUserAccess();
  const { id } = useParams<{ id: string }>();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || 1;
  const limit = searchQuery.value.limit || 10;
  const dateFrom = searchQuery.value.dateFrom;
  const dateTo = searchQuery.value.dateTo;
  const status = searchQuery.value.status;
  const valuesToBeRemoved = [
    queriesParams.tab,
    queriesParams.page,
    queriesParams.limit,
    queriesParams.status,
    queriesParams.dateFrom,
    queriesParams.dateTo
  ];

  const [tabs, setTabs] = useState<IdentityTabsT[]>([{ name: 'Billing', key: 'billing' }]);
  const activeTab = searchQuery.value.tab || tabs[0]?.key;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalType, setConfirmationModalType] = useState<'disable' | 'enable'>('disable');

  const canViewVerificationEvents = isAllowed(userAccess, ['identity_verifications.view']);
  const canUpdateMerchant = isAllowed(userAccess, ['identity_merchants.update']);

  const addTab = (tab: IdentityTabsT) => {
    if (tabs.some(t => t.key === tab.key)) return;
    setTabs([tab, ...tabs]);
  }

  const sortingParams = {
    ...(status
      ? ['billing'].includes(activeTab)
        ? { type: typeof status === 'string' ? [status] : status }
        : { status: typeof status === 'string' ? [status] : status }
      : {}),
    ...(dateFrom ? { startDate: dateFrom } : {}),
    ...(dateTo ? { endDate: dateTo } : {}),
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  const grantAccessMutation = useMutation((data: AccessDataT) => api.updateIdentityMerchantAccess(data), {
    onError: (error: AxiosError) => {
      logError(error);
      feedbackInit({ message: error.response?.data.message, componentLevel: true, type: 'danger' });
    },
    onSuccess: data => {
      feedbackInit({ message: `Merchant ${confirmationModalType}d successfully`, componentLevel: true, type: 'success' });
    }
  });

  const {
    data: merchantData,
    isLoading,
    refetch: refetchMerchantData
  } = useQuery(`IDENTITY_MERCHANT_${id.toUpperCase()}`, () => api.getIdentityMerchant(id), {
    onError: (e: AxiosError) => {
      logError(e);
      feedbackInit({
        message: "There has been an error getting this merchant's information",
        type: 'danger'
      });
    }
  });

  const {
    data: resolvedData,
    isFetching,
    refetch
  } = useQuery(
    [`MERCHANT_VERIFICATIONS_${id.toUpperCase()}`, searchQuery.value],
    () => api.getMerchantVerifications({ page, limit, sortingParams, id }),
    {
      keepPreviousData: true,
      enabled: activeTab === 'verification',
      onError: (error: AxiosError) => {
        logError(error);
        let message = 'There has been an error getting verification events.';
        if (searchQuery.value?.sorterType)
          message = `There has been an error so we could not find any results for ${searchQuery.value?.sorterType}. `;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          },
          componentLevel: false
        });
      }
    }
  );

  const {
    data: resolvedBillingData,
    isFetching: isFetchingBillingData,
    refetch: refetchBillingData
  } = useQuery(
    [`MERCHANT_BILLING_${id.toUpperCase()}`, searchQuery.value],
    () => api.getIdentityBillings({ page, limit, sortingParams, id }),
    {
      keepPreviousData: true,
      enabled: activeTab === 'billing',
      onError: error => {
        logError(error);
        let message = 'There has been an error getting verification events.';
        if (searchQuery.value?.sorterType)
          message = `There has been an error so we could not find any results for ${searchQuery.value?.sorterType}. `;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          },
          componentLevel: false
        });
      }
    }
  );

  const exportVerifications = async (format: 'csv' | 'xlsx', close: () => void, fieldsToExport: Array<string>) => {
    try {
      const res = await api.getMerchantVerifications({ sortingParams, format, fieldsToExport, id });

      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Verifications at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: <> - Successfully exported</>,
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your verfication list file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };
  const exportBilling = async (format: FileFormatType, close: () => void, fieldsToExport: string | string[]) => {
    try {
      const res = await api.getIdentityBillings({ sortingParams, format, fieldsToExport, id });

      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Billings at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: <> - Successfully exported</>,
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your billing list file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };
  const merchantEnabled = merchantData?.active;

  const confirmationModal = (type: 'disable' | 'enable') => {
    const content = {
      heading: type === 'disable' ? 'Disable Identity for this merchant?' : 'Enable Identity for this merchant?',
      description:
        type === 'disable'
          ? 'Please confirm that you want to disable Identity service for this merchant. This will prevent the merchant from performing any identity checks on customers within any region.'
          : 'Please confirm that you want to enable Identity services for this merchant. They will be able to performing identity checks on customers within enabled regions.',
      content: '',
      firstButtonText: 'Cancel',
      secondButtonText: `Yes, ${type.charAt(0).toUpperCase() + type.slice(1)}`,
      secondButtonAction: async () => {
        await grantAccessMutation.mutateAsync({ kora_id: id, enable: type === 'enable' });
        refetchMerchantData();
      },
      secondButtonColor: type === 'disable' ? '#F32345' : '',
      equalFooterBtn: true,
      completedHeading: `Service ${type.charAt(0).toUpperCase() + type.slice(1)}d!`,
      completedDescription: `You have successfully ${type}d the service for this merchant.`,
      secondaryCompletedModal: true
    };

    return content;
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  useLayoutEffect(() => {
    if (canViewVerificationEvents) {
      addTab({ name: 'Verification Events', key: 'verification' });
    }
  }, []);

  return (
    <div className="identity-merchant-details">
      <div className="content-i">
        <div className="content-box">
          {isLoading ? (
            <div className="row">
              <div className="col-sm-12">
                <LoadingPlaceholder type="text" content={1} section={4} />
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-sm-12">
                  <button
                    type="button"
                    className="btn btn-link mb-2 identity__back-btn"
                    onClick={() => history.push('/dashboard/identity?tab=merchants')}
                  >
                    <i className="os-icon os-icon-arrow-left7" />
                    <span>Back to Merchants</span>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <HeroSection
                    title={
                      <>
                        {`Identity Service for ${merchantData?.name || ''}`}{' '}
                        <span className={`identity__status ${merchantEnabled ? 'enabled' : 'disabled'}`}>
                          {merchantEnabled ? 'Enabled' : 'Disabled'}
                        </span>
                      </>
                    }
                    description={
                      'Here, you can view and modify the access and fee configurations for this merchant. Merchants inherit the default configurations for Identity until they are uniquely modified. You may also view the checks done by the merchant and their billing history.'
                    }
                    button={
                      canUpdateMerchant
                        ? {
                            name: `${merchantEnabled ? 'Disable' : 'Enable'} Service`,
                            action: () => {
                              setShowConfirmationModal(true);
                              setConfirmationModalType(merchantEnabled ? 'disable' : 'enable');
                            },
                            color: merchantEnabled ? 'secondary' : 'success',
                            icon: <i className="os-icon os-icon-power" />
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 mt-3 mb-5">
                  <div className="os-tabs-w">
                    <div className="os-tabs-controls os-tabs-complex">
                      <ul className="nav nav-tabs">
                        {tabs.map(tab => {
                          return (
                            <li className="nav-item" key={tab.key}>
                              <button
                                type="button"
                                onClick={() => searchQuery.setQuery({ tab: tab.key }, true)}
                                onKeyDown={() => searchQuery.setQuery({ tab: tab.key }, true)}
                                className={`nav-link details--custom--button ${tab.key === activeTab ? 'active' : ''}`}
                              >
                                <span className="tab-label">{tab.name}</span>
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {activeTab === 'verification' && canViewVerificationEvents && (
                      <VerificationEventTable
                        resolvedData={resolvedData}
                        isFetching={isFetching}
                        exportVerifications={exportVerifications}
                      />
                    )}
                    {activeTab === 'billing' && (
                      <BillingTable
                        resolvedData={resolvedBillingData}
                        isFetching={isFetchingBillingData}
                        exportData={exportBilling}
                        refetch={refetchBillingData}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {showConfirmationModal && (
        <Modal {...confirmationModal(confirmationModalType)} close={() => handleCloseConfirmationModal()} size="md" />
      )}
    </div>
  );
}
