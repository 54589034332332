import React from 'react';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import Table from '+containers/Dashboard/Shared/Table';
import { IBillingData, IdentityTablePropsT } from '+types';
import { capitalizeAllCharacters, capitalizeFirst, capitalizeRemovedash, formatAmount, getDate, getTime } from '+utils';

import './index.scss';

export default function BillingTable({ resolvedData, isFetching, refetch, exportData }: IdentityTablePropsT<IBillingData>) {
  const renderFields = (merchant: IBillingData[]) => {
    return (
      <div className="nav-content active">
        <div className="element-box-tp">
          <div>
            {merchant?.map(each => {
              return (
                <div key={`billing_${each.reference}`} className="div-table --row ibt" role="button" tabIndex={0}>
                  <div>
                    <span className="body-row-header">Date:</span>
                    <span>
                      {getDate(each?.date_created)}
                      <span className="annotation" style={{ marginLeft: '5px', color: 'rgba(74, 74, 74, 0.5)' }}>
                        {getTime(each?.date_created)}
                      </span>
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">Billing for:</span>
                    {each.verification_reference ? (
                      <Copyable
                        text={each?.verification_reference}
                        textToCopy={each?.verification_reference}
                        spanClassName="identity-copy-btn-span"
                        buttonClassName="identity-copy-btn"
                      />
                    ) : (
                      'N/A'
                    )}
                  </div>
                  <div>
                    <span className="body-row-header">Category:</span>
                    <span className="identity__type">{capitalizeAllCharacters(each?.verification_type)}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Verification Class:</span>
                    <span>{capitalizeRemovedash(each?.type)?.replace('Id ', 'ID ') || 'N/A'}</span>
                  </div>
                  <div>
                    <span className="body-row-header">ID Type:</span>
                    <span>{capitalizeAllCharacters(capitalizeRemovedash(each?.identity_type))}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Amount:</span>
                    <span>
                      {formatAmount(each?.amount)} {capitalizeAllCharacters(each.currency)}
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">Status:</span>
                    <span className={['paid', 'success'].includes(each?.status) ? 'paid' : 'unpaid'}>{capitalizeFirst(each?.status)}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="element-box-tp mt-5">
      <Table
        className="ibt"
        tableHeadings={['Date', 'Billing for', 'Category', 'Billing Type', 'ID Type', 'Amount', 'Status']}
        totalItems={resolvedData?.paging ? resolvedData.paging.total_items : 0}
        pageSize={resolvedData?.paging ? resolvedData.paging.page_size : 0}
        annotation="invoices"
        emptyStateHeading="There are no events yet."
        filterName="Fees/Bills"
        filterExportAction={exportData}
        type="identity-billing"
        filterKeywordPlaceholder="Search by Billing for ..."
        filterAmountPlaceholder="Search by Amount"
        filterTotalCount={resolvedData?.paging?.total_items}
        filterHasAdvancedFilter={false}
        data={resolvedData?.data || []}
        loading={isFetching}
        hasPagination
      >
        {renderFields(resolvedData?.data)}
      </Table>
    </section>
  );
}
