import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import { TIconNames } from '+containers/Dashboard/Shared/Icons/IconNames';
import Table from '+containers/Dashboard/Shared/Table';
import { useSetUserAccess } from '+hooks';
import { capitalizeAllCharacters, capitalizeFirst, capitalizeRemovedash, getDate, getTime, history, isAllowed } from '+utils';

import './index.scss';

import { ExportActionType } from '+types';

interface IVerificationEvent {
  reference: string;
  individual_business: string;
  result: string;
  type: string;
  region: string;
  date_created: string;
  full_name?: string;
  id_status: string;
  status: string;
  country: string;
  identity_type_label: string;
  merchant: {
    reference: string;
    name: string;
    email: string;
  };
}

const VerificationEventTable = ({
  allMerchants,
  resolvedData,
  isFetching,
  exportVerifications
}: {
  allMerchants?: boolean;
  resolvedData: any;
  isFetching: boolean;
  exportVerifications: (
    format: ExportActionType['format'],
    close: ExportActionType['close'],
    fieldsToExport: ExportActionType['fieldsToExport']
  ) => void;
}) => {
  const userAccess = useSetUserAccess();

  const canShowExport = isAllowed(userAccess, ['identity_verifications.export']);
  const canViewEventDetails = isAllowed(userAccess, ['identity_verification_details.view']);

  const getDetailsLink = (reference: string) => `/dashboard/identity/verifications/${reference}`;

  const handleClick = (reference: string) => {
    if (canViewEventDetails) {
      history.push(getDetailsLink(reference));
    }
  };

  const renderFields = (merchant: IVerificationEvent[]) => {
    return (
      <div className="nav-content active">
        <div className="element-box-tp">
          <div>
            {merchant?.map(each => {
              let resultIcon = {
                name: 'dash',
                stroke: '#A9AFBC'
              };
              if (each?.result?.toLowerCase() === 'valid') resultIcon = { name: 'check', stroke: '#24B314' };
              if (each?.result?.toLowerCase() === 'invalid') resultIcon = { name: 'invalid', stroke: '#F32345' };
              if (each?.result?.toLowerCase() === 'pending') resultIcon = { name: 'pending', stroke: '#FFC107' };
              let serviceStatus = 'disabled';
              if (each?.status?.toLowerCase() === 'found') serviceStatus = 'enabled';
              if (each?.status?.toLowerCase() === 'failed') serviceStatus = 'failed';
              return (
                <div
                  key={`merchant_${each.reference}`}
                  className={`div-table --row ivt ${allMerchants ? 'all-merchants' : ''}`}
                  role="button"
                  tabIndex={0}
                  onClick={() => handleClick(each.reference)}
                  onKeyDown={() => handleClick(each.reference)}
                >
                  <div>
                    <span className="body-row-header">Reference:</span>

                    <span className="imt__merchant-name">{each?.reference}</span>
                  </div>
                  {allMerchants && (
                    <div>
                      <span className="body-row-header">Merchant:</span>

                      <span>{capitalizeFirst(each?.merchant?.name)}</span>
                    </div>
                  )}
                  <div>
                    <span className="body-row-header">Invalid / Business:</span>
                    <span className={['not found', ''].includes(each?.full_name?.toLowerCase() || '') ? 'not-found' : ''}>
                      {each?.full_name || 'Not found'}
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">ID Status:</span>

                    <span className={`identity__service-status --${serviceStatus}`} />
                    <span
                      className={['undefined', 'failed', 'not found'].includes(String(each?.id_status).toLowerCase()) ? 'undefined' : ''}
                    >
                      {capitalizeRemovedash(each?.status || '') || 'Not found'}
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">Event Result:</span>
                    <Icon name={resultIcon.name as TIconNames} width={13} height={13} stroke={resultIcon.stroke} />
                    <span className={['undefined', 'failed'].includes(each?.result?.toLowerCase()) ? 'undefined' : ''}>
                      {!['undefined', 'failed', undefined].includes(each?.result?.toLowerCase())
                        ? capitalizeFirst(each?.result || '')
                        : 'Undefined'}
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">Category:</span>
                    <span className="ivt__type">{capitalizeAllCharacters(each?.type)}</span>
                  </div>
                  {!allMerchants && (
                    <div>
                      <span className="body-row-header">ID Type:</span>

                      <span>{capitalizeAllCharacters(each?.identity_type_label || 'N/A')}</span>
                    </div>
                  )}
                  <div>
                    <span className="body-row-header">Region:</span>
                    <span>{capitalizeAllCharacters(each?.country)}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Date Added:</span>
                    <span>{getDate(each?.date_created)}</span>
                    <span className="annotation" style={{ marginLeft: '5px', color: 'rgba(74, 74, 74, 0.5)' }}>
                      {getTime(each?.date_created)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const headers = ['Reference', 'Individual / Business', 'ID Status', 'Event Result', 'Category', 'Region', 'Date Created'];
  if (allMerchants) {
    headers.splice(1, 0, 'Merchant');
  } else {
    headers.splice(4, 0, 'ID Type');
  }

  return (
    <section className="element-box-tp mt-5">
      <Table
        className={`ivt ${allMerchants ? 'all-merchants' : ''}`}
        tableHeadings={headers}
        totalItems={resolvedData?.paging ? resolvedData.paging.total_items : 0}
        pageSize={resolvedData?.paging ? resolvedData.paging.page_size : 0}
        annotation="verifications"
        emptyStateHeading="There are no events yet."
        filterName={`${resolvedData?.paging?.total_items || 0} Verification Events`}
        filterExportAction={exportVerifications}
        type="verification-events"
        filterKeywordPlaceholder="Search by Reference, Individual / Business ..."
        filterAmountPlaceholder="Search by Amount"
        filterTotalCount={resolvedData?.paging?.total_items}
        filterShowExport={canShowExport as boolean}
        filterHasAdvancedFilter={false}
        loading={isFetching}
        data={resolvedData?.data || []}
        hasPagination
      >
        {renderFields(resolvedData?.data)}
      </Table>
    </section>
  );
};

export default VerificationEventTable;
