import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';

import './index.scss';

type BannerType = 'warning' | 'success';

const containerClassName: Record<BannerType, string> = {
  warning: 'banner-warning',
  success: 'banner-success'
};
const DISPLAY_TEXT = {
  vba_count: 'Unused VBA count is low. Click Edit to increase assigned VBAs.'
} as const;
export type ModeType = keyof typeof DISPLAY_TEXT;
export interface IProductConfigProps {
  type: BannerType;
  mode: ModeType | string;
}
const ProductConfigBanner: React.FC<IProductConfigProps> = ({ type = 'warning', mode }) => {
  const iconType = (status: BannerType): JSX.Element => {
    switch (status) {
      case 'warning':
        return <Icon name="infoSolid" fill="#FA9500" height={25} />;
      case 'success':
        return <Icon name="success" fill="#FA9500" height={25} />;
      default:
        return <Icon name="infoSolid" fill="#FA9500" height={25} />;
    }
  };
  return (
    <div
      className="product-config-banner-wrapper"
      role={type === 'warning' ? 'alert' : 'status'}
      aria-live="polite"
      aria-label={type === 'warning' ? 'Warning banner' : 'Success banner'}
    >
      <div className={`${containerClassName[type]} banner-container`}>
        <span aria-hidden="true">{iconType(type)}</span>
        <div>
          <span className="banner-text">{DISPLAY_TEXT[mode as keyof typeof DISPLAY_TEXT]}</span>
        </div>
      </div>
    </div>
  );
};
export default ProductConfigBanner;
