import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFeedbackHandler, useLargeExportDownloader, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import LargeExportModal from '+shared/LargeExportModal';
import Table from '+shared/Table';
import { FileFormatType, IBulkPayoutTableProps, ITableData, Params, TransactionType } from '+types';
import { APIDownload, capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, switchStatus } from '+utils';
import useStore from '+zustandStore';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

const BulkPayoutTable = <T extends TransactionType>({ data, loading, paging, page, sortingParams, limit }: IBulkPayoutTableProps<T>) => {
  const { id } = useParams<Params>();
  const feedbackInit = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const activeCurrency = searchQuery.value.currency || 'NGN';
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const { profile } = useStore(state => state);

  const { getDownload } = useLargeExportDownloader('bulk-transactions');

  useEffect(() => {
    getDownload();
  }, []);

  const exportFile = async (format: FileFormatType, close: () => void, fieldsToExport: string | string[]) => {
    const parameterizeArray = (key: string, arr: string | string[]) => {
      if (!Array.isArray(arr)) return encodeURIComponent(arr);
      const urlArr = arr.map(encodeURIComponent);
      return urlArr.join(`&${key}[]=`);
    };
    const fields = parameterizeArray('fieldsToExport', fieldsToExport);
    try {
      const res = await api.exportBulkPayouts(id, activeCurrency, sortingParams, format, fields);
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'excel';
        APIDownload(res, `Payouts at ${getDate(Date.now())}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: (
            <>
              {' '}
              - Successfully exported <strong>bulk-payout transactions.</strong>
            </>
          ),
          type: 'success'
        });
      }
      close();
    } catch (error) {
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error exporting bulk-payouts`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const transactions: ITableData<TransactionType> = {
    type: 'payouts',
    className: '--bulkpayouts-table',
    rowKey: 'unique_reference',
    rowURL: '/dashboard/payouts',
    emptyStateHeading: 'No records yet',
    emptyStateMessage: 'There are no bulk payouts yet.',
    annotations: 'transaction(s)',
    fields: each => ({
      data: {
        status: (
          <>
            <span
              className={`status-pill smaller ${switchStatus(each?.status === 'requires_auth' ? 'pending' : each?.status)} ${
                each?.status === 'complete' ? 'green' : ''
              }`}
            />
            <span>{capitalizeRemovedash(each?.status || 'N/A')}</span>
          </>
        ),
        Transaction_id: <span className="trxn-id">{each?.unique_reference || 'N/A'}</span>,
        Recipient_name: <span className="merch-name">{capitalize(each?.bank_account?.account_name || 'Not Available')}</span>,
        amount: (
          <>
            <span>
              <strong>{formatAmount(each?.amount)}</strong>
            </span>
            <span className="annotation">{each?.currency}</span>
          </>
        ),
        'Date / Time': (
          <>
            <span>{getDate(each?.transaction_date)}</span>
            <span className="annotation">{getTime(each?.transaction_date)}</span>
          </>
        )
      }
    })
  };

  const tableDataKeys = Object.keys(transactions.fields({} as TransactionType).data);

  return (
    <>
      <LargeExportModal close={() => setLargeExportModal(false)} email={profile?.email ?? ''} visible={showLargeExportModal} />
      <div className="row">
        <div className="col-sm-12">
          <Table
            data={data}
            loading={loading}
            className={`${transactions.className || ''}`}
            tableWrapperClassName="table-responsive"
            renderFields
            hasPagination
            tableHeadings={tableDataKeys}
            annotation={transactions.annotations}
            rowKey={transactions.rowKey}
            rowURL={transactions.rowURL}
            pageSize={paging?.page_size || 0}
            totalItems={paging?.total_items || 0}
            emptyStateHeading={transactions.emptyStateHeading || ''}
            emptyStateMessage={transactions.emptyStateMessage || ''}
            filterType="bulk-transactions"
            filterShowExport
            filterHasAdvancedFilter={false}
            filterExportAction={exportFile}
            filterKeywordPlaceholder="Search Transaction"
            filterTotalCount={paging?.total_items}
            filterActiveCurrency={activeCurrency}
            hideTable={data?.length === 0}
          >
            {transactions.fields}
          </Table>
        </div>
      </div>
    </>
  );
};
export default BulkPayoutTable;
