/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { UseBaseQueryResult, useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';

import { useFeedbackHandler, useReducerState, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import Table from '+shared/Table';
import { APIDownload, capitalize, capitalizeRemovedash, filteredOutObjectProperty, getDate, logError } from '+utils';

import CurrencyPicker from '../Shared/CurrencyPicker';
import ToolTip from '../Shared/Tooltip';
import AccountHolderDetails from './AccountHoldersDetails';
import { summaryInfo, switchTabData, TSummaryData, TSummaryInfo, VirtualAccountsTabsList } from './data';
import UpgradeRequestDetails from './UpgradeRequestDetails';
import VirtualAccountDetails from './VirtualAccountDetails/index';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

import './index.scss';

const api = new APIRequest();

type Merchant = {
  name: string;
  id: number;
};

interface Data {
  data: TSummaryData;
  paging?: {
    page_size?: number;
    total_items?: number;
  };
}

export interface VirtualAccountList {
  status: string;
  account_number: string;
  account_name: string;
  provider_name: string;
  korapay_reference: string;
  account_reference: string;
  bank_name: string;
  merchant: Merchant;
}

type stateType = {
  summary: TSummaryInfo[];
  tableData: any;
  isFilterVisible: boolean;
  sorterType: string;
  exportVisible: boolean;
  isUpgradeRequestModal: boolean;
  reference: string;
};

type TPendingUprageReqArg = { data: [{ status: string }] };

const VirtualAccounts = () => {
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();

  const activeCurrency = searchQuery.value.currency || 'NGN';
  const activeTab = searchQuery.value.tab || 'overview';
  const dateFrom = searchQuery.value.dateFrom || '';
  const currentActiveTab = VirtualAccountsTabsList.find(tab => tab.key === activeTab);

  const activeSubTab = searchQuery.value.subTab || currentActiveTab?.subTabs?.[0].key;
  const currentActiveSubTab = currentActiveTab?.subTabs?.find(tab => tab.key === activeSubTab);
  const [showLargeExportModal, setLargeExportModal] = useState<boolean>(false);

  const setDateParam = () => {
    if (activeTab === 'fixed_virtual_accounts') return ['dateFrom', 'dateTo'];
    if (activeTab !== 'fixed_virtual_accounts') return ['dateCompleted'];
    return [];
  };

  const onTransactionsPage = activeTab === 'transactions';

  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || 10;
  const status = searchQuery.value?.status;
  const tier = searchQuery.value?.tier;
  const searchTerm = searchQuery.value?.keyword;
  const sortingParams = {
    status: onTransactionsPage ? [status] : status,
    tier,
    ...(onTransactionsPage ? { keyword: searchTerm } : { searchTerm }),
    currency: activeCurrency,
    ...filteredOutObjectProperty({ ...searchQuery.value, dateCreated: dateFrom || undefined }, [
      'tab',
      'page',
      'limit',
      'sorterType',
      'status',
      'subTab',
      ...setDateParam()
    ])
  };

  const availableCurrencies: string[] = ['NGN', 'USD', 'EUR', 'GBP'];

  const [state, setState] = useReducerState<stateType>({
    summary: [],
    tableData: {},
    isFilterVisible: false,
    sorterType: '',
    exportVisible: false,
    isUpgradeRequestModal: false,
    reference: ''
  });

  const currentActiveTabHasAtLeastOneSubTab = !!currentActiveTab?.subTabs?.length;

  const { data, isLoading, refetch }: UseBaseQueryResult<Data, boolean> = useQuery(
    [currentActiveTab?.queryKey ?? currentActiveSubTab?.queryKey, page, limit, activeCurrency, sortingParams],
    () => (currentActiveSubTab?.query ?? currentActiveTab?.query)?.(page, limit, sortingParams),
    {
      cacheTime: 0,
      onError: errorData => {
        logError(errorData);
        feedbackInit({
          message: `There has been an error fetching the requested data`,
          type: 'danger'
        });
      }
    }
  );

  const { data: upgradeRequestData } = useQuery(
    [state.reference],
    () => api.getSingleFixedVirtualBankAccountUpgradeRequests(state.reference),
    {
      enabled: state.reference !== '',
      onError: e => {
        logError(e);
        feedbackInit({
          message: `There has been an error fetching the upgrade request`,
          type: 'danger'
        });
      }
    }
  );

  useEffect(() => {
    setState({
      summary: summaryInfo(data as unknown as TSummaryData, searchQuery.value, tab =>
        searchQuery.setQuery({ tab })
      ) as unknown as TSummaryInfo[]
    });
  }, [data]);

  useEffect(() => {
    const tab = currentActiveTabHasAtLeastOneSubTab ? activeSubTab : activeTab;
    setState({ tableData: switchTabData(tab || '', searchQuery.value, data?.paging || {}) });
    refetch();
  }, [activeTab, activeSubTab, data]);

  const tableDataKeys = Object.keys(state.tableData || {}).length > 0 ? Object.keys(state?.tableData?.fields({})?.data) : [];

  const exportRecords = async (format: string, close: () => void, fieldsToExport: string[]) => {
    try {
      const res: Awaited<void | undefined | (Blob & { status: number })> = await (currentActiveSubTab || currentActiveTab)?.query?.(
        page,
        limit,
        sortingParams,
        true,
        format,
        fieldsToExport
      );
      if (res?.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `${activeTab}_${getDate(String(Date.now()))}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: <> - Successfully exported record.</>,
          type: 'success'
        });
      }
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your record`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const updateUpgradeRequestModalState = () => {
    setState({ isUpgradeRequestModal: false });
  };

  let pendingUpgradeReq: number | null;
  if (activeSubTab === 'upgrade_requests') {
    pendingUpgradeReq = (data as unknown as TPendingUprageReqArg)?.data?.filter(req => req.status === 'pending').length || null;
  }

  return (
    <div className="content-i">
      <div className="content-box">
        <section className="os-tabs-w">
          <div className="nav-tabs os-tabs-controls os-tabs-complex">
            <ul className="nav" role="tablist">
              {VirtualAccountsTabsList.map(({ label, key }) => (
                <li
                  className="nav-item"
                  key={key}
                  style={{ position: 'relative' }}
                  onClick={() => {
                    searchQuery.setQuery({ tab: key, page: '1', currency: activeCurrency }, true);
                  }}
                  onKeyUp={() => {
                    searchQuery.setQuery({ tab: key, page: '1', currency: activeCurrency }, true);
                  }}
                  role="tab"
                  tabIndex={0}
                >
                  <button type="button" className={`nav-link ${activeTab === key && 'active'}`}>
                    {capitalize(label)}
                  </button>
                </li>
              ))}
            </ul>
            {activeTab !== 'account_holders' && (
              <CurrencyPicker
                options={availableCurrencies}
                onChange={currency => searchQuery.setQuery({ currency, page: '1', subTab: '' })}
                activeCurrency={activeCurrency}
                bordered
              />
            )}
          </div>
        </section>

        <article>
          <h5 className="form-header">{capitalizeRemovedash(activeTab)}</h5>
          <div className="form-desc no-underline" style={{ width: '60%', fontWeight: '500' }}>
            {currentActiveTab?.description}
          </div>
        </article>
        {activeTab === 'fixed_virtual_accounts' && activeCurrency !== 'NGN' && (
          <div className="os-tabs-controls os-tabs-complex">
            <ul className="nav nav-tabs" role="tablist">
              {currentActiveTab?.subTabs?.map(({ label, key }) => {
                return (
                  <li className="nav-item" key={key} role="tab">
                    <div
                      role="button"
                      onClick={() => {
                        searchQuery.setQuery({ limit: '10', page: '1', subTab: key });
                      }}
                      onKeyUp={() => {
                        searchQuery.setQuery({ limit: '10', page: '1', subTab: key });
                      }}
                      className={`nav-link ${key === activeSubTab && 'active'}`}
                      tabIndex={0}
                    >
                      {label}
                      {key === 'upgrade_requests' && pendingUpgradeReq && <div className="new-messages-count">{pendingUpgradeReq}</div>}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {activeTab === 'overview' && (
          <section className="info-summary-container">
            <section className="first-section">
              {state.summary.map((summary: TSummaryInfo) => (
                <div className="info-summary-item" key={summary.label}>
                  <p className="label">
                    {summary.label}
                    {summary.tooltip && (
                      <span>
                        <ToolTip type={`issuance-summary-${summary.label}`} image={InfoIcon} message={summary.tooltip} />
                      </span>
                    )}
                  </p>
                  <h3 className="value" aria-label="summary header">
                    {summary.value ?? '...'}
                  </h3>
                  <p className="description">{summary.description}</p>
                  {summary.link && (
                    <button type="button" className="summary-link" onClick={summary.link?.linkAction}>
                      {summary.link.linkText}
                      <span className="icon-w">
                        <span className="os-icon os-icon-arrow-up-right" />
                      </span>
                    </button>
                  )}
                </div>
              ))}
            </section>
          </section>
        )}

        {activeTab !== 'overview' && (
          <div key={activeTab + activeSubTab}>
            <Table
              header={null}
              className={state.tableData?.className}
              tableHeadings={tableDataKeys}
              loading={isLoading}
              data={(data?.data || []) as unknown as TSummaryData[]}
              renderFields
              hasPagination
              annotation={state.tableData.annotations}
              rowKey={state.tableData?.rowKey}
              rowURL={state.tableData?.rowURL}
              rowFn={
                activeSubTab === 'upgrade_requests' && activeTab === 'fixed_virtual_accounts'
                  ? (row: { [key in 'reference' | 'status']: string }) => {
                      setState({ isUpgradeRequestModal: true, reference: row.reference });
                    }
                  : undefined
              }
              pageSize={data?.paging?.page_size}
              totalItems={data?.paging?.total_items}
              emptyStateHeading={state.tableData?.emptyStateHeading || ''}
              tableWrapperClassName="table-responsive"
              emptyStateMessage={state.tableData?.emptyStateMessage || ''}
              filterType={state.tableData?.type || ''}
              type="fixed-virtual-accounts"
              filterExportAction={exportRecords as unknown as () => void}
              filterName={state.tableData?.filterTitle || ''}
              filterTotalCount={data?.paging?.total_items}
              filterQueryIDPlaceholder={state.tableData?.filterQueryIDPlaceholder}
              filterShowExport={currentActiveTab?.key !== 'account_holders'}
              filterActiveCurrency={activeCurrency}
              filterHasAdvancedFilter={false}
              showDateFilter
            >
              {state.tableData?.fields}
            </Table>
          </div>
        )}
      </div>
      {state.isUpgradeRequestModal && <UpgradeRequestDetails data={upgradeRequestData} updateModalState={updateUpgradeRequestModalState} />}
    </div>
  );
};

export default function VirtualBankAccounts() {
  return (
    <div className="virtual-accounts__container">
      <Switch>
        <Route exact path="/dashboard/virtual-accounts" component={VirtualAccounts} />
        <Route path="/dashboard/virtual-accounts/account-holders/:id">
          <AccountHolderDetails />
        </Route>
        <Route path="/dashboard/virtual-accounts/:id">
          <VirtualAccountDetails />
        </Route>
      </Switch>
    </div>
  );
}
