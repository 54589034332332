import React from 'react';

import { IVbaLimitDetailsProps } from '+types';

import './index.scss';

const VbaLimitDetails: React.FC<IVbaLimitDetailsProps> = ({ content }) => {
  return (
    <div className="vba-limit-wrapper">
      <div className="row" aria-labelledby="vba-limit-section">
        <section className="col-12 col-lg-4" aria-labelledby="total-vba-label">
          <div className="vba-limit-label" id="total-vba-label">
            Total VBAs Assigned
          </div>
          <div className="vba-limit-number">
            <span>{content.total_vba || 0}</span>
          </div>
        </section>
        <section className="col-12 col-lg-4" aria-labelledby="used-vba-label">
          <div className="vba-limit-label" id="used-vba-label">
            Used VBAs
          </div>
          <div className="vba-limit-number" aria-live="polite">
            <span>{content.used_vba || 0}</span>
          </div>
        </section>
        <section className="col-12 col-lg-4" aria-labelledby="unused-vba-label">
          <div className="vba-limit-label" id="unused-vba-label">
            Unused VBAs
          </div>
          <div
            className={content.low_balance_warning ? 'vba-limit-number-warning' : 'vba-limit-number'}
            aria-live="assertive"
            aria-label={content.low_balance_warning ? 'Warning: Unused VBAs' : 'Unused VBAs'}
          >
            <span>{content.unused_vba || 0}</span>
          </div>
        </section>
      </div>
    </div>
  );
};
export default VbaLimitDetails;
