/* eslint-disable no-nested-ternary */
import React from 'react';
import { useQuery } from 'react-query';

import Icon from '+containers/Dashboard/Shared/Icons';
import Table from '+containers/Dashboard/Shared/Table';
import { useFeedbackHandler, useReducerState, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import ToolTip from '+shared/Tooltip';
import { IGenerateReportTable } from '+types';
import { filteredOutObjectProperty, getCustomiseDateTimeFormat, getDate, getTime, logError, queriesParams } from '+utils';

import './generateReportTable.scss';

const generateReportStatus = {
  success: 'completed',
  failed: 'failed',
  processing: 'processing'
};
const mapTypesToActions = {
  failed: 'failed'
};
const api = new APIRequest();

const GenerateReportsTable: React.FC<IGenerateReportTable> = ({ actionFn }) => {
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';
  const status = searchQuery.value.status || [];

  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, [queriesParams.limit, queriesParams.status, queriesParams.page])
  };

  const [state, setState] = useReducerState({
    selectedId: [],
    reportsData: []
  });
  const { selectedId, reportsData } = state;
  const {
    data: resolvedData,
    refetch,
    isFetching
  } = useQuery(
    ['GET_ALL_SPOOLING_REPORTS', page, limit, sortingParams],
    () => api.getAllSpoolingTransactionReportsService(page, limit, sortingParams),
    {
      refetchOnMount: 'always',
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: `'There has been an error fetching reports`,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  );
  const data = resolvedData?.data?.data || [];

  const paging = resolvedData?.data?.paging || [];

  const spinnerAction = () => {
    try {
      if (selectedId && selectedId.length > 0) {
        const updatedData = data.map(item => {
          if (selectedId.includes(item.id)) {
            return { ...item, status: generateReportStatus.processing };
          }
          return item;
        });
        setState({ reportsData: updatedData });
      }
    } catch (error) {
      logError(error);
    }
  };
  const generateReportsTableData = {
    className: '--generate-report',
    rowKey: 'id',
    emptyStateHeading: 'No transaction report yet',
    emptyStateMessage: 'It looks like you have not requested any processor transaction report yet.',
    annotations: 'items',
    fields: (iter: any) => ({
      data: {
        reports: (
          <div
            style={{
              display: 'flex',
              paddingBottom: '0px',
              paddingLeft: '0px',
              paddingTop: iter.status === generateReportStatus.success ? '2px' : '0px'
            }}
          >
            {iter.status === generateReportStatus.success ? (
              <div>
                <Icon name="check" height={10} width={20} stroke="#24B314" />
              </div>
            ) : iter.status === generateReportStatus.processing ? (
              <div style={{ padding: '0px', marginLeft: '-9px' }}>
                <ToolTip
                  type="processing-report"
                  message={
                    <>
                      <p>Your report has been requested.</p>
                      <p> Please refresh page to know status of report.</p>
                    </>
                  }
                >
                  <Icon name="processIcon" height={16} />
                </ToolTip>
              </div>
            ) : (
              <div style={{ padding: '0px' }} data-tooltip-id="retry-tooltip">
                <ToolTip
                  type="retry-report"
                  message={
                    <>
                      <p>Your report could not be generated.</p>
                      <p>Please select checkbox to retry.</p>
                    </>
                  }
                >
                  <Icon name="warningTriangle" height={18} width={18} />
                </ToolTip>
              </div>
            )}
            <div
              className={
                iter.status === generateReportStatus.success
                  ? 'report-text-column-sucess'
                  : iter.status === generateReportStatus.processing
                  ? 'report-text-column-process'
                  : 'report-text-column-retry'
              }
            >
              <span>{`Processor Report for ${getCustomiseDateTimeFormat(
                iter?.start_date,
                'YYYY/MM/DD, h:mm a'
              )} - ${getCustomiseDateTimeFormat(iter?.end_date, 'YYYY/MM/DD, h:mm a')}
            `}</span>
            </div>{' '}
            {iter.status === generateReportStatus.success ? (
              <div style={{ padding: '0px', marginLeft: '-25px' }} data-tooltip-id="success-tooltip">
                <ToolTip
                  type="success-report"
                  centered
                  message={
                    <>
                      <p>Your report is available for download.</p>
                      <p>Check your Kora email for download link.</p>
                    </>
                  }
                >
                  <Icon name="tipsIcon" height={12} width={12} />
                </ToolTip>
              </div>
            ) : null}
          </div>
        ),
        date_created: (
          <div>
            {getDate(iter?.createdAt)}&nbsp;<span className="date-created-column">{getTime(iter?.createdAt)}</span>
          </div>
        )
      }
    })
  };

  const tableDataKeys = Object.keys(generateReportsTableData.fields({}).data);
  const emptyStateMessage = (): JSX.Element => {
    return (
      <div>
        It looks like you have not requested any processor transaction report yet.
        {actionFn ? (
          <div className="empty-text-container " onClick={() => actionFn()} onKeyDown={() => actionFn()} role="button" tabIndex={0}>
            <Icon name="fileIcon" />
            <span className="empty-text" style={{ marginLeft: '10px' }}>
              Generate Processor Report
            </span>
          </div>
        ) : null}
      </div>
    );
  };
  const newReportData = reportsData && reportsData?.length > 0 ? reportsData : data;
  return (
    <section className="generate-wrapper element-box-tp">
      <Table
        showCheckbox
        showBulkActionSpinnerAction={spinnerAction}
        storeSelectedItem={(seletedItems: T) => setState({ selectedId: seletedItems })}
        checkboxStatusesToShow={[generateReportStatus.failed]}
        checkBoxStatusesKey="status"
        showBulkActionModal={false}
        checkBoxKey={generateReportsTableData.rowKey}
        bulkAction={mapTypesToActions.failed}
        onSuccessBulkAction={refetch}
        bulkState={reportsData?.find(item => item.status === mapTypesToActions.failed)}
        hideSelectedExport
        type="generate-report"
        subType="retry_generate_report"
        header={null}
        hasFilter={false}
        className={generateReportsTableData.className || ''}
        tableHeadings={tableDataKeys}
        loading={isFetching}
        data={newReportData}
        renderFields
        hasPagination
        annotation={generateReportsTableData.annotations}
        rowKey={generateReportsTableData.rowKey}
        pageSize={paging?.page_size || 0}
        totalItems={paging?.total_items || 0}
        emptyStateHeading={generateReportsTableData.emptyStateHeading || ''}
        tableWrapperClassName="table-responsive"
        emptyStateMessage={emptyStateMessage()}
        hideTable={data.length === 0}
      >
        {generateReportsTableData?.fields}
      </Table>
    </section>
  );
};
export default GenerateReportsTable;
