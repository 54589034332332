/* eslint-disable camelcase */
import React from 'react';
import { useQuery } from 'react-query';

import Table from '+dashboard/Shared/Table';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { IEvent, Nullable, TabsT } from '+types';
import { durationMonth, durationToday, durationWeek, getDate, getTime, logError } from '+utils';

import '../../index.scss';

const api = new APIRequest();
interface IUserActivityProps {
  userId?: string | number | null;
  userName?: string | null;
}

const UserActivity = ({ userId, userName }: IUserActivityProps) => {
  const { feedbackInit } = useFeedbackHandler();

  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page ?? '1';
  const limit = searchQuery.value.limit ?? '25';
  const activeTab = searchQuery.value.activeTab ?? 'All Time';

  const tabs: Record<TabsT, Nullable<string>[]> = {
    'Last 24 Hours': durationToday(),
    'Last 7 Days': durationWeek(),
    'Last 30 Days': durationMonth(),
    'All Time': [null, null]
  };

  const {
    data: audits,
    isLoading,
    isFetching,
    refetch
  } = useQuery(
    [`AUDIT_LOGS_${userId}`, page, limit, ...tabs[activeTab as TabsT]],
    () => api.getLogs(page, limit, ...(tabs[activeTab as TabsT] as [string, string]), String(userId)),
    {
      keepPreviousData: true,
      onError: error => {
        if (error) {
          logError(error);
          feedbackInit({
            message: `There has been an error getting this ${userName ?? 'user'} activity log`,
            type: 'danger',
            action: {
              action: () => refetch(),
              name: 'Try again'
            }
          });
        }
      },
      enabled: !!userId
    }
  );

  const paging = audits?.paging;

  const renderEvents = (event: IEvent) => {
    return {
      data: {
        created_at: (
          <div style={{ margin: 0, padding: 0 }}>
            {getDate(event?.created_at)}&nbsp;<span style={{ color: '#A9AFBC' }}>{getTime(event.created_at)}</span>
          </div>
        ),
        event: (
          <div style={{ margin: 0, padding: 0 }}>
            <span style={{ fontWeight: 500 }}>{event?.event}</span> : {event?.description}
          </div>
        ),
        event_id: <span>{event?.event_id}</span>
      }
    };
  };

  return (
    <div className="userdetails-container">
      <div className="tab-pane active" id="tab_activity">
        <Table
          className="--audit-log-table"
          hasPagination
          header={false}
          tableHeadings={['Timestamp', 'Event', 'Event ID']}
          totalItems={paging?.total_items || 0}
          pageSize={paging?.page_size || 0}
          loading={isLoading || isFetching}
          loaderBackground="#f5f6f6"
          annotation="actions"
          tableWrapperClassName="table-responsive"
          emptyStateHeading="There are no logs yet"
          renderFields
          hasFilter={false}
          data={audits?.data || []}
        >
          {renderEvents}
        </Table>
      </div>
    </div>
  );
};

export default UserActivity;
