import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import Icon from '+containers/Dashboard/Shared/Icons';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSearchQuery from '+hooks/useSearchQuery';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import Table from '+shared/Table';
import { APIDownload, filteredOutObjectProperty, getDate, isAllowed, logError, queriesParams, storageDataKey } from '+utils';

import './index.scss';

import CurrencyPicker from '+containers/Dashboard/Shared/CurrencyPicker';
import { FileFormatType } from '+types';

import { allPreferencesTableContent } from './merchantsPaymentPreferencesData';

const api = new APIRequest();

function MerchantsPaymentPreferences() {
  const qc = useQueryClient();
  const availableCurrencies = (qc.getQueryData(storageDataKey.AVAILABLE_CURRENCIES) as string[]) || [];
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = Number(searchQuery.value.page) || 1;
  const limit = Number(searchQuery.value.limit) || 10;
  const [activeCurrency, setActiveCurrency] = useState('NGN');

  const valuesToBeRemoved = [queriesParams.tab, queriesParams.page, queriesParams.limit];
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  const userAccess = useSetUserAccess();

  const { data: merchants, isFetching } = useQuery(
    ['MERCHANTS_PREFERENCE_REQUESTS', page, sortingParams, limit],
    () => api.getMerchantsPaymentPreferencesRequest({ page, ...sortingParams, limit }),
    {
      refetchOnMount: true,
      onError: error => {
        logError(error);
        feedbackInit({
          message: 'There has been an error fetching all merchants, Please try again',
          type: 'danger'
        });
      }
    }
  );
  const preferences = allPreferencesTableContent({ data: merchants?.data || [] });

  const exportMerchantList = async (format: FileFormatType, close: () => void, fieldsToExport: string | string[]) => {
    try {
      const res = await api.exportMerchants(fieldsToExport, format, sortingParams);
      const type = format || 'xlsx';
      APIDownload(res, `Preferences list at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: (
          <>
            - Successfully exported <strong>{merchants?.paging.total_items} records.</strong>
          </>
        ),
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your preference list file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  return (
    <div className="element-box payment-preferences">
      <div className="element-box-heading">
        <div className="heading-box-mmd">
          <div className="payment-preferences-title">
            <h5 className="form-header" data-testid="page-title">
              Payment Preferences{' '}
            </h5>

            <CurrencyPicker
              options={availableCurrencies}
              allowedOptions={['NGN']}
              onChange={value => {
                setActiveCurrency(value);
              }}
              activeCurrency={activeCurrency}
              id="merchants-payment-preferences__currency-switch"
              showDropDown={false}
            />
          </div>
          <div className="form-desc no-underline mb-0" data-testid="page-subtitle">
            Here, you can review and manage pending payment preferences request for all merchants.
          </div>
          <div className="learn-more">
            <Icon name="lightBulb" height={18} width={18} /> <a href="link">Learn More about Payment Preferences.</a>
          </div>
        </div>
      </div>
      <div className="payment-preferences__table-wrapper">
        <Table
          className={preferences.className}
          loading={isFetching}
          data={preferences?.data || []}
          renderFields
          hasPagination
          rowKey={preferences?.rowKey}
          rowURL={preferences?.rowURL}
          rowUrlQuery={preferences?.rowUrlQuery}
          tableHeadings={['Merchant', 'Request Status', 'Merchant ID', 'Contact', 'Date Requested']}
          totalItems={merchants?.paging.total_items}
          pageSize={merchants?.paging.page_size}
          tableWrapperClassName="table-responsive table-wrapper"
          annotation="Payment Preferences"
          type="payment-preferences"
          filterType="payment-preferences"
          filterHasAdvancedFilter={false}
          filterKeywordPlaceholder="Search..."
          filterQueryIDPlaceholder="Merchant ID"
          filterExportAction={exportMerchantList}
          filterShowExport={isAllowed(userAccess, ['bank_transfer_settings_requests.view']) as boolean}
          emptyStateHeading={preferences?.emptyStateHeading || ''}
          emptyStateMessage={preferences.emptyStateMessage || ''}
        >
          {preferences?.fields}
        </Table>
      </div>
    </div>
  );
}

export default MerchantsPaymentPreferences;
