import React, { ElementRef, ReactElement, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import Banner from '+containers/Dashboard/Shared/Banner';
import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import SecondaryDetails from '+containers/Dashboard/Shared/SecondaryDetails';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import Table from '+shared/Table';
import { capitalize, history, logError } from '+utils';

import { accountStatusBanner } from '../data';
import { kycDetails, routeQuery, tableData } from './data';
import KycInformation from './KycInformation';
import ManageAccountHolderModal from './ManageAccountHolderModal';
import { TAccountHolderModal, TAccountHolderTransaction, TEvent, TVirtualBankAccount } from './TypesAccountHolders';

import buildingIcon from '+assets/img/dashboard/building.svg';

import './index.scss';

const api = new APIRequest();

export default function AccountHolderDetails() {
  const { id } = useParams<{ id: string }>();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = Number(searchQuery.value.page) || 1;
  const limit = searchQuery.value.limit || 10;
  const tab = searchQuery.value.tab || 'Account Numbers';
  const [buttonState, setButtonState] = useState(false);
  const routesNav = useRef<ElementRef<'section'>>(null);
  const queryClient = useQueryClient();

  const [modal, setModal] = useState<TAccountHolderModal>({
    modalType: null,
    modalInfo: {} as IModalProps
  });

  const activeQuery = routeQuery[tab as keyof typeof routeQuery];

  const { data: holder, isLoading: fetchingHolder } = useQuery(['ACCOUNT_HOLDERS', id], () => api.getAccountHolder(id), {
    refetchOnMount: 'always',
    onSuccess: () => {
      queryClient.invalidateQueries('ACCOUNT_HOLDER_TABLE');
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: "There has been an error fetching this account holder's information",
        type: 'danger'
      });
    }
  });

  const { data: res, isLoading } = useQuery(['ACCOUNT_HOLDER_TABLE', tab, page, limit, id], () => activeQuery.query(id), {
    onError: error => {
      logError(error);
      feedbackInit({
        message: activeQuery.errorMsg,
        type: 'danger'
      });
    }
  });

  const { data: kycData, isLoading: fetchingKyc } = useQuery(['ACCOUNT_HOLDER_KYC', tab, id], () => api.getAccountHolderKycInfo(id), {
    keepPreviousData: true,
    onError: error => {
      logError(error);
      feedbackInit({
        message: "There has been an error fetching this account holder's information",
        type: 'danger'
      });
    }
  });

  const paging = res?.paging || {};
  const dataObject = res?.data || [];

  const tableDataKeys = Object.keys(tableData(tab)?.fields?.({} as TVirtualBankAccount & TEvent & TAccountHolderTransaction)?.data || {});
  const tableContent = tableData(tab);

  let renderKyc;
  if (fetchingKyc || fetchingHolder) renderKyc = <LoadingPlaceholder type="text" section={2} content={1} rows={2} />;
  else if (!kycData || !holder) renderKyc = <h4 className="mt-5 text-center">Unable to Fetch Kyc Information</h4>;
  else renderKyc = <KycInformation data={{ ...kycData, ...(holder as unknown as object) }} />;

  let noAccountHolder;
  if (fetchingHolder) noAccountHolder = <LoadingPlaceholder type="text" section={2} content={1} rows={2} />;
  else if (!holder) noAccountHolder = <h4 className="mt-5 text-center">Unable To Fetch Account Holder</h4>;

  return (
    <div className="content-box">
      <div className="row mb-3">
        {!fetchingHolder && (
          <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7" />
            <span style={{ fontSize: '13px', fontWeight: '500' }}>Go back to Account Holders</span>
          </button>
        )}
      </div>
      <div className="row account-holder">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div className="content-details-box">
              {noAccountHolder || (
                <>
                  <div className="content-details-head --content-details">
                    <div className="content-details-title">
                      <div className="title-wrapper">
                        <img className="title-icon pt-0 mr-2" src={buildingIcon} alt="" /> <span>{capitalize(holder.account_type)}</span>
                        <h3 className="mt-2">
                          {capitalize(holder.first_name)} {capitalize(holder.last_name)}
                        </h3>
                      </div>
                    </div>

                    <ManageAccountHolderModal
                      accountHolder={{ status: holder?.status as string, reason: holder?.status_reason as string }}
                      modalType={modal.modalType}
                      setModal={setModal}
                      setButtonState={setButtonState}
                      accountReference={id}
                    />
                  </div>
                  <div style={{ marginTop: -16, marginBottom: 30 }}>
                    {kycData?.account_summary.account_status === 'pending' && (
                      <Banner
                        actionBtn={() => {
                          routesNav.current?.scrollIntoView({ behavior: 'smooth' });
                          searchQuery.setQuery({ tab: 'KYC' });
                        }}
                        actionText="Go to section"
                        text="This account holder and their KYC details have not yet been verified."
                      />
                    )}
                  </div>
                  {fetchingKyc ? (
                    <LoadingPlaceholder type="text" section={2} content={1} rows={2} />
                  ) : (
                    <>
                      <SecondaryDetails
                        title="Here's a summary of this account holder"
                        leftSpacing="0"
                        hideLine
                        data={kycDetails({ ...holder, ...kycData })}
                      />
                      {accountStatusBanner({
                        status: holder?.status,
                        reason: holder?.status_reason,
                        type: 'holder',
                        onClick: () => setModal(state => ({ ...state, modalType: 'actionReason' }))
                      })}
                    </>
                  )}

                  <section className="os-tabs-w" ref={routesNav}>
                    <div className="nav-tabs os-tabs-controls os-tabs-complex">
                      <ul className="nav">
                        {Object.keys(routeQuery).map(nav => (
                          <li className="nav-item" key={nav} style={{ position: 'relative' }} role="tab" tabIndex={0}>
                            <button
                              type="button"
                              onClick={() => searchQuery.setQuery({ tab: nav })}
                              className={`nav-link ${tab === nav ? 'active' : ''}`}
                            >
                              {nav}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </section>
                </>
              )}

              {tab === 'KYC' ? (
                renderKyc
              ) : (
                <Table
                  header={`(${res?.data?.length || 0}) ${tab}`}
                  className={tableContent.className || ''}
                  loading={isLoading}
                  data={dataObject}
                  renderFields
                  hasPagination
                  tableHeadings={tableDataKeys}
                  annotation={tableContent.annotations}
                  rowKey={tableContent.rowKey}
                  isRowClickable={tableContent?.type !== 'events'}
                  rowURL={tableContent.rowURL}
                  pageSize={paging?.page_size || 0}
                  totalItems={paging?.total_items || 0}
                  emptyStateHeading={tableContent.emptyStateHeading || ''}
                  tableWrapperClassName="table-responsive"
                  emptyStateMessage={tableContent.emptyStateMessage || ''}
                  hasFilter={false}
                >
                  {tableContent?.fields}
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={modal.modalType !== null}
        close={() => setModal({ modalType: null, modalInfo: {} as IModalProps })}
        heading={modal.modalInfo?.heading as string}
        description={modal.modalInfo?.description}
        content={
          typeof modal.modalInfo?.content === 'function'
            ? (modal.modalInfo as unknown as { content: () => ReactElement })?.content()
            : modal.modalInfo?.content
        }
        size={modal.modalInfo?.size || 'md'}
        secondButtonText={modal.modalInfo?.secondButtonText || ''}
        secondButtonAction={() => modal.modalInfo?.secondButtonAction?.()}
        secondButtonDisable={!buttonState}
        secondButtonActionIsTerminal={modal.modalInfo?.secondButtonActionIsTerminal}
        completedHeading={modal.modalInfo?.completedHeading}
        completedDescription={modal.modalInfo?.completedDescription}
        completedModalSize={modal.modalInfo?.completedModalSize}
        showButtons={modal.modalInfo?.showButtons}
        secondButtonColor={modal.modalInfo?.secondButtonColor}
        hideSecondButton={modal.modalInfo?.hideSecondButton}
        firstButtonText={modal.modalInfo?.firstButtonText}
        secondaryCompletedModal
      />
    </div>
  );
}
