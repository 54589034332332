/* eslint-disable no-nested-ternary */
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useFeedbackHandler, useLargeExportDownloader, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { mapContentToAction, PausedPaymentsModalContent } from '+shared/data/PausedPayments';
import Icon from '+shared/Icons';
import LargeExportModal from '+shared/LargeExportModal';
import Modal from '+shared/Modal';
import Table from '+shared/Table';
import useBulkActionStore from '+store/zustand';
import { FileFormatType } from '+types';
import { APIDownload, filteredOutObjectProperty, formatAllTime, getDate, getPresentDate, isAllowed, queriesParams } from '+utils';
import useStore from '+zustandStore';

import { switchTabData, TPausedPayment, TStage } from './data';

import './index.scss';

const infoData = [
  {
    heading: 'Paused Payments',
    subHeading:
      'Transactions may be automatically paused or put on hold when they encounter issues while processing. These transactions can be reprocessed or canceled.',
    actionText: 'Any transaction shown here requires attention and needs to be treated as quickly as possible.'
  }
];

const api = new APIRequest();

const ProcessAndCancelAllBtn = ({ setOpenModal, isDisabled }: { setOpenModal: Dispatch<SetStateAction<string>>; isDisabled: boolean }) => (
  <div className="pause-action-btn">
    <button
      className={`btn ${isDisabled ? 'button-disabled' : ''}`}
      type="button"
      onClick={() => setOpenModal('process_multiple')}
      disabled={isDisabled}
    >
      <Icon name="check" stroke="green" />
      <span className="process-all-text">Process all</span>
    </button>
    <span className="divider-sm" />
    <button
      className={`btn ${isDisabled ? 'button-disabled' : ''}`}
      type="button"
      onClick={() => setOpenModal('cancel_multiple')}
      disabled={isDisabled}
    >
      <Icon name="cancel" />
      <span className="cancel-all-text">Cancel all</span>
    </button>
    <span className="divider-sm" />
  </div>
);

function PausedPayments() {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const { profile } = useStore(state => state);
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const rowRef = useRef<string[]>([]);
  const [openModal, setOpenModal] = useState<TStage | ''>('');
  const searchQuery = useSearchQuery();
  const current = Number(searchQuery.value.page || 1);
  const activeTab = searchQuery.value.tab || 'pending';
  const limit = Number(searchQuery.value.limit || 10);
  const activeCurrencyTab = searchQuery.value.currency || 'USD';
  const availableCurrencies = ['USD', 'GBP'];
  const bulkInfo = useBulkActionStore((state: any) => state.bulkInfo);
  const setBulkInfo = useBulkActionStore((state: any) => state.setBulkInfo);
  const savedBulkInfo = useRef(bulkInfo);
  const valuesToBeRemoved = [
    queriesParams.tab,
    queriesParams.page,
    queriesParams.limit,
    queriesParams.activeCurrency,
    queriesParams.dateFrom,
    queriesParams.dateTo
  ];
  const [isDisabled, setIsDisabled] = useState(false);
  const queryClient = useQueryClient();

  const dateFrom = searchQuery.value.dateFrom || getPresentDate().dateFrom;
  const dateTo = searchQuery.value.dateTo || getPresentDate().dateTo;
  const sortingParams = {
    dateFrom: formatAllTime(dateFrom),
    dateTo: formatAllTime(dateTo),
    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  const modalContent = mapContentToAction[openModal as TStage] || {};

  const { getDownload } = useLargeExportDownloader('Settlements');

  const { data: pausedPayments, isLoading } = useQuery(
    ['PAUSED_PAYMENTS', searchQuery.value, activeCurrencyTab, current, limit, activeCurrencyTab],
    () => api.getPausedPayments(current, limit, activeCurrencyTab, sortingParams),
    {
      onError: () => {
        feedbackInit({
          message: 'There has been an error getting paused transactions, please try again',
          type: 'danger'
        });
      }
    }
  );

  const { mutateAsync } = useMutation(
    () => {
      const action = openModal.split('_')[0];
      return rowRef.current.length
        ? api.unpausePayment({ references: rowRef.current, action })
        : api.unpauseAllPayments(action, activeCurrencyTab);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`PAUSED_PAYMENTS`);
      },
      onError: (e: { response: { data: { message: string } } }) => {
        feedbackInit({
          message: e?.response?.data?.message || 'An error occured! Could not process paused payment',
          type: 'danger'
        });
      }
    }
  );

  const tableRowBtnAction = (arg: TStage | '', id: string) => {
    setOpenModal(arg);
    rowRef.current = [id];
  };

  useEffect(() => {
    const retrievedPausedTransactions = pausedPayments || { data: [] };

    setIsDisabled(isLoading || retrievedPausedTransactions.data.length === 0);
    setBulkInfo(retrievedPausedTransactions);

    return () => {
      if (savedBulkInfo.current !== undefined) {
        setBulkInfo(savedBulkInfo.current);
      }
    };
  }, [isLoading, pausedPayments]);

  useEffect(() => {
    getDownload();
  }, []);

  const exportFile = async (format: FileFormatType, close: () => void, fieldsToExport: string | string[]) => {
    try {
      const res = await api.exportTransactions('paused/payouts', sortingParams, format, activeCurrencyTab, fieldsToExport);
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'excel';
        APIDownload(res, `Paused transaction at ${getDate(Date.now())}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: (
            <>
              {' '}
              - Successfully exported <strong>paused transactions.</strong>
            </>
          ),
          type: 'success'
        });
      }
      close();
    } catch (error) {
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error exporting paused transactions`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const tableData = switchTabData(activeCurrencyTab, tableRowBtnAction);

  const tableDataKeys = Object.keys(tableData?.fields?.({} as unknown as TPausedPayment).data || {});

  return (
    <div className="content-i">
      <div className="content-box">
        <LargeExportModal close={() => setLargeExportModal(false)} email={profile.email as string} visible={showLargeExportModal} />
        {infoData.map(item => (
          <>
            <div className="element-box-heading settlements-settings__heading">
              <div className="heading-box-mmd" key={item.actionText}>
                <h5 className="form-header">{item.heading}</h5>
                <div className="form-desc no-underline">{item.subHeading}</div>
              </div>
            </div>
            <p className="action-text">{item.actionText}</p>
          </>
        ))}

        <div className="os-tabs-controls os-tabs-complex mb-4">
          <ul className="nav nav-tabs">
            {availableCurrencies?.map((currency: string) => (
              <li className="nav-item" key={currency}>
                <button
                  type="button"
                  className={`nav-link ${activeCurrencyTab === currency ? 'active' : ''}`}
                  onClick={() => searchQuery.setQuery({ currency })}
                >
                  {currency}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <Modal
          size="sm"
          heading={modalContent.heading || 'Action required to proceed'}
          close={() => {
            setOpenModal('');
            rowRef.current = [];
          }}
          visible={!!openModal}
          secondButtonText={modalContent.secondButtonText || 'Confirm'}
          secondButtonColor={modalContent?.secondButtonColor}
          secondButtonAction={modalContent?.secondButtonAction?.(mutateAsync)}
          content={<PausedPaymentsModalContent refs={rowRef.current} stage={openModal} />}
          completedDescription={modalContent.completedDescription || 'Successfully initiated'}
          completedActionText="Dismiss"
          description={undefined}
          secondaryCompletedModal
          completedImage={modalContent.completedImage}
          completedHeading={modalContent.completedHeading || 'Success'}
        />

        <section className="element-box-tp mt-5">
          <Table
            moreFilterBtn={<ProcessAndCancelAllBtn setOpenModal={setOpenModal} isDisabled={isDisabled} />}
            hasBulkAction
            className={tableData?.className || ''}
            tableHeadings={tableDataKeys}
            loading={isLoading}
            data={pausedPayments?.data}
            renderFields
            hasPagination
            annotation={tableData?.annotations}
            rowKey={tableData?.rowKey}
            rowURL={tableData?.rowURL}
            checkBoxKey="unique_reference"
            pageSize={pausedPayments?.paging?.page_size}
            totalItems={pausedPayments?.paging?.total_items}
            emptyStateHeading={tableData?.emptyStateHeading || ''}
            tableWrapperClassName="table-responsive"
            emptyStateMessage={tableData?.emptyStateMessage || ''}
            type="paused-payments"
            filterType="paused-payments"
            subType="paused_payments"
            filterKeywordPlaceholder="Search Transactions"
            filterQueryIDPlaceholder="Transaction ID"
            filterExportAction={exportFile}
            filterTotalCount={pausedPayments?.paging?.total_items}
            filterActiveCurrency={activeCurrencyTab}
            filterDefaultStatus={activeTab}
            filterShowExport={isAllowed(userAccess, ['payouts.export']) as boolean}
          >
            {tableData?.fields}
          </Table>
        </section>
      </div>
    </div>
  );
}

export default PausedPayments;
