import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import { VbaCountsDataType, VbaCountType } from '+types';
import { cleanInput } from '+utils';

import '../index.scss';

export const VBA_LIMIT_MAX_COUNT = 200;

export type TVbaIncreaseFormFields = 'counts' | 'reason';

interface IIncreasedVbaCountProps {
  content: VbaCountType;
  vbaCountsData: VbaCountsDataType;
  handleFormChange: (value: string, type: TVbaIncreaseFormFields) => void;
  consent: boolean;
  setConsent: (value: boolean) => void;
}

const IncreasedVbaCountForm: React.FC<IIncreasedVbaCountProps> = ({ content, vbaCountsData, handleFormChange, consent, setConsent }) => {
  const handleInputChange = (value: string, type: TVbaIncreaseFormFields) => {
    if (type === 'counts') {
      handleFormChange(value, 'counts');
    }
  };
  const handleTextAreaChange = (value: string, type: TVbaIncreaseFormFields) => {
    if (type === 'reason') {
      handleFormChange(value, 'reason');
    }
  };
  return (
    <div className="currency-modal__content vba-edit-count-form">
      <div className="vba-form-container">
        <div>
          <div className="vba-edit-form-label vba-limit-form-margin-bottom-5">
            <span>Available VBAs</span>
          </div>
          <input
            value={(content as VbaCountType).total_vba || 0}
            type="number"
            disabled
            className="form-input-vba-edit-count"
            aria-label="Available VBAs"
          />
        </div>
        <div className=" mt-24 vba-limit-form-margin-bottom-24">
          <div className="pt-11 vba-edit-form-label">
            <span>Enter number for new batch of VBA </span>
          </div>
          <input
            value={vbaCountsData.num}
            min={0}
            type="number"
            onChange={e => handleInputChange(cleanInput(e.target.value) as string, 'counts')}
            className="form-input-vba-edit-count"
            aria-label="Enter number for new batch of VBAs"
          />
        </div>
        <div>
          <div className="vba-limit-form-margin-bottom-5">
            <span className="vba-edit-form-label">Reason for VBA increase</span>
          </div>
          <textarea
            rows={8}
            maxLength={VBA_LIMIT_MAX_COUNT}
            id="description"
            name="description"
            className="form-control"
            value={vbaCountsData.reason}
            onChange={e => handleTextAreaChange(cleanInput(e.target.value) as string, 'reason')}
            aria-label="Reason for VBA increase"
          />
        </div>
      </div>
      <div className="vba-edit-blue-label-wrapper">
        <div className="vba-limit-padding-top-11 ">
          <Icon name="infoRounded" fill="#2376F3" width="16px" height="16px" />
        </div>
        <div className="vba-edit-text-label font-weight-300-vba-edit">
          <span className="vba-limit-medium-font">Important:</span> The changes you have made here will affect the total number of VBAs for
          this merchant
        </div>
      </div>
      <label className="prod-consent-wrapper">
        <input checked={consent} type="checkbox" onChange={() => setConsent(!consent)} aria-label="Consent to proceed" />
        <span>Yes, I understand the implications of this action</span>
      </label>
    </div>
  );
};

export default IncreasedVbaCountForm;
