import { EditDetailsCardPropsType } from '+types';
import { capitalizeRemovedash } from '+utils';

const customiseHeaderLabels = ['vba_count'] as const;

const customiseEditableCardHeader = (key: string): string => {
  switch (key) {
    case 'vba_count':
      return 'VBA Count';
    default:
      return '';
  }
};
export const conditionToDisplayBanner = (modeType: string, contentType: EditDetailsCardPropsType['content']): boolean => {
  if (modeType === 'vba_count' && typeof contentType === 'object' && 'low_balance_warning' in contentType) {
    return contentType.low_balance_warning;
  }
  return false;
};
export const getHeaderLabel = (title: string): string => {
  if (customiseHeaderLabels.includes(title as (typeof customiseHeaderLabels)[number])) {
    return customiseEditableCardHeader(title);
  }
  return capitalizeRemovedash(title);
};
