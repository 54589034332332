/* eslint-disable camelcase */
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import Table from '+shared/Table';
import { IEvent, IRouteParams, Nullable } from '+types';
import { capitalizeRemovedash, durationMonth, durationToday, durationWeek, getDate, logError, specialisedDate } from '+utils';

import './index.scss';

type TabsT = 'today' | 'this_week' | 'this_month' | 'all_time';

const api = new APIRequest();

export default function AuditLogs() {
  const { id } = useParams<IRouteParams>();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const paginationPage = searchQuery.value.page ?? '1';
  const limit = searchQuery.value.limit ?? '25';
  const activeTab = searchQuery.value.activeTab ?? 'all_time';

  const tabs: Record<TabsT, string | string[] | Nullable<string>[] | null[]> = {
    today: durationToday(),
    this_week: durationWeek(),
    this_month: durationMonth(),
    all_time: [null, null]
  };

  const { data, isFetching, refetch } = useQuery(
    [`MERCHANT_AUDIT_LOGS_${id}`, paginationPage, limit, ...tabs[activeTab as TabsT]],
    () => api.getMerchantLogs(id, Number(paginationPage), Number(limit), ...(tabs[activeTab as TabsT] as [string, string])),
    {
      onError: error => {
        if (error) {
          logError(error);
          feedbackInit({
            message: `There has been an error getting activity for the specified time period`,
            type: 'danger',
            action: {
              action: () => refetch(),
              name: 'Try again'
            }
          });
        }
      }
    }
  );

  const paging = data?.paging;
  const renderEvents = (event: IEvent) => ({
    data: {
      timestamp: `${getDate(event.created_at)}, ${specialisedDate(event.created_at, 'hh:mm A')}`,
      event: event.description,
      eventId: event.event_id
    }
  });

  return (
    <div className="element-box">
      <div className="os-tabs-w mb-3">
        <div className="os-tabs-controls">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button type="button" data-toggle="tab" className="nav-link active" style={{ paddingBottom: '15px' }}>
                <span className="tab-label">Events</span>
              </button>
            </li>
          </ul>
          <ul className="nav nav-pills smaller d-none d-md-flex" style={{ paddingBottom: '10px' }}>
            {Object.keys(tabs).map(tab => (
              <li className="nav-item" key={tab}>
                <button
                  data-toggle="tab"
                  type="button"
                  onClick={() => searchQuery.setQuery({ activeTab: tab })}
                  className={`nav-link ${activeTab === tab && 'active'}`}
                >
                  {capitalizeRemovedash(tab)}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="tab-content">
          <div className="tab-pane active" id="tab_activity">
            <div className="table-responsive">
              <Table
                className="--audit-log-table"
                hasPagination
                header={false}
                tableHeadings={['Timestamp', 'Event', 'Event ID']}
                data={data?.data || []}
                totalItems={paging?.total_items || 0}
                pageSize={paging?.page_size || 0}
                loading={isFetching}
                renderFields
                tableWrapperClassName="table-responsive"
                annotation="actions"
                emptyStateHeading="There are no logs yet"
                hasFilter={false}
                emptyStateMessage="You do not have any audit logs at the moment."
              >
                {renderEvents}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
