import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { ExportActionType } from '+types';
import { APIDownload, filteredOutObjectProperty, getDate, isAllowed, logError, queriesParams } from '+utils';

import AddMerchantsModal from '../Components/AddMerchantsModal';
import HeroSection from '../Components/HeroSection';
import MerchantsTable from '../Components/MerchantsTable';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function Merchants() {
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const { feedbackInit } = useFeedbackHandler();
  const [showAddMerchantModal, setShowAddMerchantModal] = useState(false);
  const closeAddMerchantModal = () => setShowAddMerchantModal(false);
  const openAddMerchantModal = () => setShowAddMerchantModal(true);
  const page = searchQuery.value.page || 1;
  const limit = searchQuery.value.limit || 10;
  const valuesToBeRemoved = [
    queriesParams.tab,
    queriesParams.page,
    queriesParams.limit,
    queriesParams.status,
    queriesParams.dateCreatedFrom,
    queriesParams.dateCreatedTo,
    queriesParams.dateFrom,
    queriesParams.dateTo
  ];
  const { dateFrom } = searchQuery.value;
  const { dateTo } = searchQuery.value;
  const { status } = searchQuery.value;
  const { dateCreatedFrom } = searchQuery.value;
  const { dateCreatedTo } = searchQuery.value;

  const sortingParams = {
    ...(status ? { active: status === 'true' } : {}),
    ...(dateFrom ? { startDate: dateFrom } : {}),
    ...(dateTo ? { endDate: dateTo } : {}),
    ...(dateCreatedFrom ? { startDate: dateCreatedFrom } : {}),
    ...(dateCreatedTo ? { endDate: dateCreatedTo } : {}),

    ...filteredOutObjectProperty(searchQuery.value, valuesToBeRemoved)
  };

  const canAddMerchant = isAllowed(userAccess, ['identity_merchants.create']);

  const {
    data: resolvedData,
    isFetching,
    refetch
  } = useQuery(['MERCHANTS', searchQuery.value], () => api.getMerchantsWithAccess({ page, limit, sortingParams }), {
    keepPreviousData: true,
    onError: () => {
      let message = 'There has been an error getting verification events.';
      if (searchQuery.value?.sorterType)
        message = `There has been an error so we could not find any results for ${searchQuery.value?.sorterType}. `;
      feedbackInit({
        message,
        type: 'danger',
        action: {
          action: () => refetch(),
          name: 'Try again'
        },
        componentLevel: false
      });
    }
  });

  const exportMerchants = async (
    format: ExportActionType['format'],
    close: ExportActionType['close'],
    fieldsToExport: ExportActionType['fieldsToExport']
  ) => {
    try {
      const res = await api.getMerchantsWithAccess({ sortingParams, format, fieldsToExport });

      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Merchants at ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: <> - Successfully exported</>,
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your verfication list file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };
  return (
    <div>
      <HeroSection
        title="Merchants with Identity service"
        description="A merchant must first be granted access to the Identity service before they can perform verification checks on their customers. Here’s a list of merchants with access to Identity."
        button={
          canAddMerchant
            ? {
                name: 'Add Merchant',
                action: openAddMerchantModal,
                color: 'success',
                icon: <i className="os-icon os-icon-plus" />
              }
            : undefined
        }
      />
      <MerchantsTable resolvedData={resolvedData} isFetching={isFetching} refetch={refetch} exportData={exportMerchants} />
      {showAddMerchantModal && <AddMerchantsModal close={closeAddMerchantModal} refetchMerchant={refetch} />}
    </div>
  );
}
