/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import Icon from '+containers/Dashboard/Shared/Icons';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import EmptyState from '+shared/EmptyState';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import Modal, { IModalProps } from '+shared/Modal';
import SecondaryDetails from '+shared/SecondaryDetails';
import { capitalizeFirst, getDate, getTime, history, isAllowed, logError } from '+utils';

import ConfirmInvitation from '../components/ConfirmInvitation';
import Assignees from './components/Assignees';
import RolePermission from './components/RolePermission';

import './index.scss';

const apiRequest = new APIRequest();

export default function RoleDetails() {
  const { id } = useParams<{ id: string }>();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const queryClient = useQueryClient();

  const [roleName, setRoleName] = useState('');

  const [modal, setModal] = useState<{
    modalType: string | boolean | undefined;
    modalInfo: (Partial<IModalProps> & { action: () => Promise<unknown> }) | null;
  }>({
    modalType: undefined,
    modalInfo: null
  });

  const [tabState, setTabState] = useState({
    isActive: true,
    activeTab: 'Assignees',
    tabs: ['Assignees', 'Permissions']
  });

  const deleteUserRole = useMutation(apiRequest.deleteUserRole, {
    onError: error => {
      logError(error);
      feedbackInit({
        title: 'Roles',
        message: 'There has been an error deleting the role',
        type: 'danger'
      });
    }
  });

  const {
    data: detailsData,
    isLoading: isFetchingDetails,
    refetch: refetchDetails
  } = useQuery(`${id}_ROLE_DETAILS`, () => apiRequest.getRole(id), {
    onError: () => {
      feedbackInit({
        message: "There has been an error getting this role's information",
        type: 'danger',
        action: {
          action: () => refetchDetails(),
          name: 'Try again'
        }
      });
    }
  });
  const switchTabs = () => {
    switch (tabState.activeTab) {
      case 'Assignees':
        return <Assignees roleDetails={detailsData} />;
      case 'Permissions':
        return <RolePermission roleDetails={detailsData} />;
      default:
        return <Assignees roleDetails={detailsData} />;
    }
  };

  const updateRoleMutation = useMutation(
    () => apiRequest.updateRole(id, { name: roleName, permissions: [...new Set(detailsData.permissions)] }),
    {
      onSuccess: () => {
        refetchDetails();
      },
      onError: error => {
        logError(error);
        feedbackInit({
          message: `There has been an error updating this role`,
          type: 'danger',
          componentLevel: true
        });
      }
    }
  );

  const setEditRoleNameModal = () => {
    setModal({
      modalType: 'edit-role',
      modalInfo: {
        heading: 'Edit role name?',
        description: 'You are about to edit the name of the role.',
        content: <ConfirmInvitation saveState getRoleName={(value: string) => setRoleName(value)} roleName={roleName} />,
        completedHeading: 'Confirmed!',
        completedDescription: 'The changes you made to this role’s name have been confirmed.',
        action: () => {
          closeFeedback();
          return updateRoleMutation.mutateAsync();
        },
        secondButtonText: 'Save & Edit',
        completedModalSize: 'base',
        equalFooterBtn: true,
        headerBottomBorder: false
      }
    });
  };
  const getRoleDetails = () => {
    const roleDetails = [
      {
        'Role Name': `${capitalizeFirst(detailsData?.name)}`,
        'Role Type': `${capitalizeFirst(detailsData?.category ?? 'N/A')}`,
        'Number of assignees': `${detailsData?.numberOfAssignees}`
      },
      {
        'Date created': (
          <>
            {getDate(detailsData.createdAt)}
            <span className="smaller lighter" style={{ marginLeft: '5px' }}>
              {getTime(detailsData.createdAt)}
            </span>
          </>
        ),
        'Last modified': (
          <>
            {getDate(detailsData.createdAt)}
            <span className="smaller lighter" style={{ marginLeft: '5px' }}>
              {getTime(detailsData.updatedAt)}
            </span>
          </>
        )
      }
    ];
    return roleDetails;
  };

  const setDeleteRoleModal = () => {
    setModal({
      modalType: 'delete-role',
      modalInfo: {
        heading: 'Delete Role',
        description: (
          <span>
            Please confirm that you want to delete this role: <strong>{detailsData?.name}</strong>
          </span>
        ),
        size: 'sm',
        content: (
          <div>
            <Icon name="circledInfo" fill="#F32345" />
            <span style={{ color: '#F32345' }}>Warning: deleting a role cannot be undone</span>
          </div>
        ),
        secondButtonColor: '#F32345',
        completedHeading: 'Role Deleted',
        completedDescription: `You have successfully deleted ${detailsData?.name} role`,
        completedActionText: 'Dismiss',
        action: () => {
          return deleteUserRole.mutateAsync({
            role_id: detailsData?.id,
            permissions: detailsData?.permissions
          });
        },
        secondButtonText: 'Yes, Delete',
        secondaryCompletedModal: true,
        equalFooterBtn: true,
        headerBottomBorder: false,
        secondButtonDisable: false,
        completedAction: () => {
          queryClient.invalidateQueries(['ROLES']);
          history.push('/dashboard/users/roles');
        }
      }
    });
  };

  const setUnableToDeleteModal = () => {
    setModal({
      modalType: 'unable-to-delete',
      modalInfo: {
        heading: 'Delete Role',
        description: (
          <span>
            You cannot delete this role: <strong>{detailsData?.name}</strong>
          </span>
        ),
        content: (
          <div className="unable-to-delete">
            <Icon name="caution" width={24} height={24} />
            <span>{detailsData?.numberOfAssignees} user(s) are assigned to this role. Remove users from this role to proceed.</span>
          </div>
        ),
        action: () => Promise.resolve(),
        headerBottomBorder: false,
        hideSecondButton: true,
        footerButtonWidthIsAuto: true
      }
    });
  };

  return (
    <div className="content-box">
      <div className="row">
        <div className="col-sm-12">
          <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7" />
            <span className="back-to-roles">Back to Roles</span>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="content-details-box">
                <div className="content-details-head --stl-details-head mb-0">
                  <div className="content-details-title">
                    <div className="cd-title ml-3">
                      <div className="cd-maintitle-btn">
                        <h4 className="cd-maintitle">{capitalizeFirst(detailsData?.name || 'N/A')}</h4>
                        {!isFetchingDetails &&
                          isAllowed(userAccess, ['custom_roles.update', 'my_custom_roles.update', 'system_roles.update']) &&
                          detailsData?.category !== 'system' && (
                            <div className="cd-btns">
                              <button type="button" onClick={setEditRoleNameModal}>
                                <span>
                                  <Icon name="editPen" />
                                </span>
                                Edit role name
                              </button>
                              <div className="cd-btn-divider" />
                              <button
                                type="button"
                                className="delete-role"
                                onClick={() => {
                                  if (detailsData?.numberOfAssignees > 0) {
                                    setUnableToDeleteModal();
                                  } else {
                                    setDeleteRoleModal();
                                  }
                                }}
                                aria-label={`Delete role ${detailsData?.name}`}
                              >
                                <Icon name="trashIcon" height={16} width={16} />
                                <span>Delete Role</span>
                              </button>
                            </div>
                          )}
                      </div>
                      <div className="cd-subtitle">{capitalizeFirst(detailsData?.category ?? 'N/A')}</div>
                    </div>
                  </div>
                </div>
                <div className="content-details-body --stl-details-body">
                  <div className="row">
                    <div className="col-sm-12 mb-5">
                      <div className="os-tabs-w">
                        <div className="os-tabs-controls os-tabs-complex os-rolesummary-tabs mb-0">
                          <div className="os-rolesummary os-rolesummary-box">
                            {isFetchingDetails ? (
                              <LoadingPlaceholder type="text" content={4} />
                            ) : Object.keys(detailsData || {}).length > 0 ? (
                              <SecondaryDetails title="Role Summary" data={getRoleDetails()} />
                            ) : (
                              <EmptyState message="There is an issue getting this role information" />
                            )}
                          </div>

                          <div className="os-rolesummary">
                            <ul className="nav nav-tabs mx-3" style={{ paddingLeft: 0, paddingBottom: 0, flexWrap: 'nowrap' }}>
                              {tabState.tabs.map(tab => {
                                return (
                                  <li className="nav-item" key={tab}>
                                    <button
                                      type="button"
                                      onClick={() => setTabState({ ...tabState, activeTab: tab })}
                                      className={`nav-link ${tabState.activeTab === tab && 'active'}`}
                                      style={{ fontWeight: 600 }}
                                    >
                                      {tab}
                                    </button>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                        {switchTabs()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal.modalType && (
        <Modal
          close={() => setModal({ modalType: undefined, modalInfo: {} as IModalProps & { action: () => Promise<unknown> } })}
          heading={modal.modalInfo?.heading as string}
          description={modal.modalInfo?.description}
          content={modal.modalInfo?.content}
          size={modal.modalInfo?.size || 'md'}
          secondButtonText={modal.modalInfo?.secondButtonText as string}
          secondButtonAction={() => modal.modalInfo?.action()}
          completedHeading={modal.modalInfo?.completedHeading}
          completedDescription={modal.modalInfo?.completedDescription}
          completedAction={modal.modalInfo?.completedAction}
          secondaryCompletedModal={modal.modalInfo?.secondaryCompletedModal}
          completedModalSize={modal.modalInfo?.completedModalSize}
          equalFooterBtn={modal.modalInfo?.equalFooterBtn}
          headerBottomBorder={modal.modalInfo?.headerBottomBorder}
          secondButtonDisable={modal.modalType === 'delete-role' ? false : !roleName}
          secondButtonColor={modal.modalInfo?.secondButtonColor}
          hideSecondButton={modal.modalInfo?.hideSecondButton}
          footerButtonWidthIsAuto={modal.modalInfo?.footerButtonWidthIsAuto}
        />
      )}
    </div>
  );
}
